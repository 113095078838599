import React, { useCallback,useState,useEffect } from 'react';
import axios from 'axios'
import {Link} from 'react-router-dom'
import LoaderSmall from '../smallLoader/LoaderSmall';
import ProgressBar from '../progressbar/ProgressBar';
import {api,base} from '../utils/api'

const DragAndDrop = (props)=>{

  const [files,setFiles] = useState(null);
  const [fileIndex,setFileIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const [uploadButton, setUploadButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState([])
  const [uploadPercentage,setUploadPercentage] = useState(0)
  const [updatedAudios, setUpdatedAudios] = useState([])
  const [stemMessage, setStemMessage] = useState([])
  const [stemLoader, setStemLoader] = useState(false)
  const [processingId, setProcessingId] = useState("")
  const [drives, setDrives] = useState([])
  const [stemLocation,setStemLocation] = useState(null);
  const [driveMessage, setDriveMessage] = useState(null)
  const [count, setCount] = useState(0)
  const [stemCount, setStemCount] = useState(0)
  const [completedMessage, setCompletedMessage] = useState(null)



  const handleDrive =  (e)=>{
    const drivefolder = e.target.value;
    setStemLocation(drivefolder)
    console.log("stemlocation",drivefolder)
  }

  const processAudio = async (audioIds,flag) => {
        
        const encodedPath = encodeURIComponent(stemLocation);
        if (audioIds.length === 0){
          console.log("zero files detected")
          return
        }

        console.log("this is stemNum",flag)

        if (flag === 11) {
          console.log("Waiting for 1 minute...");
          try {
            await axios.post(base +'/restart-server/');
          } catch (error) {
            console.error('Failed to send server restart request:', error);
          }
          await new Promise((resolve) => setTimeout(resolve, 60000)); // Delay for 1 minute 
        }
        const resMessages = []; 
        const id = audioIds[0].id
        setProcessingId(id)
        setStemLoader(true)
        
    try {
        const response = await axios.get(base + `/auto-generatestems/${id}/${encodedPath}`);
        resMessages.push(response?.data?.data)
        //let cnt = updatedAudios.length - audioIds.length
        let cnt = stemCount + 1;
        setStemCount(cnt)
        setStemLoader(prev=>!prev)
    } catch (error) { 
        console.error('Error processing audio:', error);
        resMessages.push(error.message)
        setStemLoader(prev=>!prev)
    }
    setStemMessage((prev)=>[...prev,...resMessages])
    
    if (audioIds.length === 1){
      setCompletedMessage("Processing Completed...system fetching more files to start again in 2 minutes... ")
      try {
        await axios.post(base +'/restart-server/');
      } catch (error) {
        console.error('Failed to send server restart request:', error);
      }
      await new Promise((resolve) => setTimeout(resolve, 120000)); // Delay for 2 minute 
      setStemLoader(false)
      setStemMessage([])
      setUpdatedAudios([])
      fetchLastUpdatedAudio()
    }else{
      if(flag===11){
        processAudio(audioIds.slice(1),1);
      }else{
        processAudio(audioIds.slice(1),flag+1);
      }
      
    }
    
    
    
    
}

const fetchLastUpdatedAudio = async () => {
  setCompletedMessage(null)
  try {
      const response = await axios.get(base + `/getlastupdatedfiles/`);
      console.log(response?.data?.data)
    
      setUpdatedAudios(response?.data?.data);
      await processAudio(response?.data?.data,1)
     
  } catch (error) {
      console.error('Error fetching last updated audio:', error);
  }


};

  useEffect(() => {

    
      axios.get(base + `/getdrives/`).then(
          response =>{
              const res = response?.data?.data
              console.log("this is drives",res)
              setDrives(res)
          }
      ).catch(
           err =>{
           console.log(err)
           }
      )
 
    
}, []);

  

const handleDrop = useCallback(async (event) => {
  event.preventDefault();
  const audioFiles = [];
  const folder = [];

  const traverseDirectory = async (item, path = '') => {
    if (item.isFile) {
      const file = await new Promise((resolve) => item.file(resolve));
      const fileName = file.name.toLowerCase();

      if (fileName.endsWith('.mp3') || fileName.endsWith('.flac') || fileName.endsWith('.wav') || fileName.endsWith('.ogg')) {
        audioFiles.push(file);
      }
    } else if (item.isDirectory) {
      const entries = await new Promise((resolve) => item.createReader().readEntries(resolve));

      for (const entry of entries) {
        await traverseDirectory(entry, `${path}${item.name}/`);
      }
    }
  };

  for (let i = 0; i < event.dataTransfer.items.length; i++) {
    const item = event.dataTransfer.items[i];

    if (item.kind === 'file' && item.type === "audio/wav") {
      const file = item.getAsFile();
      const fileName = file.name.toLowerCase();

      if (fileName.endsWith('.mp3') || fileName.endsWith('.flac') || fileName.endsWith('.wav') || fileName.endsWith('.ogg')) {
        audioFiles.push(file);
      }
    } else if (item.webkitGetAsEntry) {
      const entry = item.webkitGetAsEntry();
      if (entry) {
        if (entry.isDirectory) {
            folder.push(entry)
        } else if (entry.isFile) {
          const file = item.getAsFile();
          const fileName = file.name.toLowerCase();

          if (fileName.endsWith('.mp3') || fileName.endsWith('.flac') || fileName.endsWith('.wav') || fileName.endsWith('.ogg')) {
            audioFiles.push(file);
          }
        }
      }
    }
  }
 
  if(folder.length > 0 ){
    console.log("this is folder",folder)
    for (let i = 0; i < folder.length; i++) {
      await traverseDirectory(folder[i]);
    }
  }
  

  setFiles(audioFiles);
}, []);




  const handleDragOver = (event) => {
    event.preventDefault();
  };

  

  const upload = async (files,count)=>{ 
    if(!stemLocation){
      setDriveMessage("please select drive for stem storage ")
      return
    }  
    const resMessages = []
    setLoader(true) 
      const axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
          }
        // onUploadProgress: (progressEvent) => {
        //       const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        //       setUploadPercentage(percentage) 
        //   },
    }
      
        const file = files[0];
        setFileIndex(file)
       
  try{
        const formData = new FormData();
        formData.append('audio', file);
        formData.append('name', file.name);
       const response = await  axios.post(api + "/media-files/upload_loopfiles/", formData, axiosConfig)
           resMessages.push(response?.data?.message)
           setFileIndex(null)
           setLoader(false)
           

  } catch(err){
           console.log(err)
           setLoader(prev=>!prev) 
           resMessages.push(err?.message) 
           setFileIndex(null)
           }
      //setSuccessMessage((prev)=>[...prev,...resMessages])
      setErrorMessage((prev)=>[...prev,...resMessages])
      setCount(count)
      // Recursive call to handle the next file in the array


      if (files.length === 1){
        setUploadButton(false)
        setLoader(false)
        if(updatedAudios.length < 1)
        {
        fetchLastUpdatedAudio()
        }
      
    }else{
      upload(files.slice(1),count+1);
    }

        
      }


  const removeItem = (index) => {
    const updatedItems = [...files]; // Create a copy of the array
    updatedItems.splice(index, 1); // Remove the item at the given index
    setFiles(updatedItems); // Update the state with the new array
  };

const handleCancel = () => {
  setFiles(null);
  setErrorMessage([]);
  setUploadButton(true);
  setLoader(false)
}




   return(
       <>
     <div className='flex items-center justify-center'>
     
     {files && <form  style={{border:"1px solid gray"}} className="container py-10 flex flex-col justify-center items-center mt-10">
     
        <ul className='h-[200px] w-[500px] mb-3  overflow-auto bg-gray-300 shadow-lg'>
          {files.length > 0 ? Array.from(files).map((file,index)=>
          <li className='flex flex-col px-1 py-1 m-1  cursor-pointer rounded-sm text-xs bg-gray-100'  key={index}><span className='flex justify-between'><p>{file.name}</p> 
         <button onClick={() => removeItem(index)} className='bg-red-400  px-2 text-xs text-gray-100 rounded-sm '>Remove</button></span>
         {fileIndex===file && 
         
          <ProgressBar duration={10} timing={5}/>
          }
           <p className={`${errorMessage[index] === "Audio Uploaded successfully" ?"text-green-600" :"text-red-500"} font-bold text-[10px] my-1`}>{errorMessage[index]}</p>
        </li>)
          :
          <li className='flex px-2 py-2 m-1  cursor-pointer rounded-sm text-sm bg-gray-100' >No Audio Files Selected</li>
          }

        </ul>
        
        <div className='flex mt-4'>
        <button onClick={handleCancel} type="button" className="bg-gray-400 h-12 rounded-sm mx-3 py-2 px-4 text-white text-1xl">Cancel</button>
        {files.length > 0  ?
         <>{uploadButton && <button onClick={()=>{upload(files,1)}} type="button" className="bg-[#0c0d4e] rounded-sm mx-3 py-2 h-12 px-4 text-white text-1xl">{loader? (<LoaderSmall/>):"Upload"}</button>}</>:""
       }
       <div className='w-45 flex flex-col ml-3'>
       {driveMessage && <p className='text-xs text-red-500'>{driveMessage}</p>}
        <p className='text-sm font-semibold'>{drives.length} Drives found. Select a drive for stem storage </p>
        <div className='flex flex-col'>
        {drives.map((drive,index)=>(
          <select key={index} onChange={handleDrive} className=' my-1 text-blue-800 h-6 border-blue-800 border rounded-md  px-3' >
          <option>{drive?.drive}</option>
          {drive.folders.map((folder)=>(
            <option value={drive?.drive+"/"+folder}>{folder}</option>
            // <option value={drive?.drive+folder}>{folder}</option>
          ))}  
          </select>
  
        ))
        }  

        </div>
       
       </div>
        </div>
        
          <p className='text-sm font-semibold text-gray-500'>uploaded {count} audios, remaining {files.length-count}</p>  
    </form>
   
    }

     {!files && <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="container h-[300px] flex justify-center items-center mt-14"
      style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center' }}
    >
      <p className="text-blue-800 font-semibold text-lg">Drag & drop files or folder here</p>
    </div>}
       

    {/* {updatedAudios.length > 0 ? 
    <div className="container w-[40%] h-[350px] ml-10   mt-10 bg-gray-800">
    <ul className='h-[330px] w-[250px] m-3 overflow-auto bg-gray-300 shadow-lg'>
    {Array.from(updatedAudios).map((audio,index)=>
    <li className='flex flex-col px-2 py-1 m-1  cursor-pointer rounded-sm text-xs bg-gray-100' >
    <div className='flex justify-between '><p>{audio.audio}</p>{processingId === audio.id && stemLoader && <LoaderSmall className="h-4 w-4" />}</div>
    {processingId === audio.id && stemLoader &&
    // <><ProgressBar duration={60} timing={1}/>
    <p className='text-xs'>processing audio...</p>
      }
    <p className={`${stemMessage[index] === "Processed Successfully"?"text-green-600" :"text-red-500"}`}>{stemMessage[index]}</p>
    </li>
    )}
    </ul>
    </div>:""
} */}
   
    </div> 

    {updatedAudios.length > 0 && <div role="dialog"  className="flex bg-gray-600 bg-opacity-50 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-450 ease-in-out"  id="modal">
                <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
                    <div class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                      {completedMessage ?  <h1 class="text-green-700 font-lg font-semibold tracking-normal leading-tight mb-4">{completedMessage}</h1>:
                        <h1 class="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">Generating Stems Please wait...</h1>}
                        {/* <div className='flex flex-col'>
                         <p className='text-sm '>Total files : {updatedAudios.length}</p>
                         <p className='text-sm '>Processed files : {stemCount}</p>
                         <p className='text-sm '>Remaining files to be processed : {updatedAudios.length-stemCount}</p>
                        </div> */}
                        <p className='text-sm '>Number of processed files : {stemCount}</p>
                        
                        <div className="container w-[320px] h-[370px] ml-10 p-[10px]   mt-10 bg-gray-800">
                        <ul className='h-[350px] w-[300px] overflow-auto bg-gray-300 shadow-lg'>
                        {Array.from(updatedAudios).map((audio,index)=>
                        <li className='flex flex-col px-2 py-1 m-1  cursor-pointer rounded-sm text-xs bg-gray-100' >
                        <div className='flex justify-between '><p>{audio.audio}</p>{processingId === audio.id && stemLoader && <LoaderSmall className="h-4 w-4" />}</div>
                        {processingId === audio.id && stemLoader &&
                        // <><ProgressBar duration={60} timing={1}/>
                        <p className='text-xs'>processing audio...</p>
                          }
                        <p className={`${stemMessage[index] === "Processed Successfully"?"text-green-600" :"text-red-500"}`}>{stemMessage[index]}</p>
                        </li>
                        )}
                        </ul>
                        </div>
                       
                        <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"  >
                            <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>}
</>
  )
};

export default DragAndDrop;
