

//export const api = "http://127.0.0.1:8000/api";
//export const base = "http://127.0.0.1:8000";

//export const api = "http://184.105.4.89:8000/api";
//export const base = "http://184.105.4.89:8000"


export const api = "https://stemgenserver.michost.top/api";
export const base = "https://stemgenserver.michost.top"



