import React from 'react'
import styled from "styled-components";

const Modal = ({ open, children })=>{
  return <>{open && <ModalWrapper role="dialog">{children}</ModalWrapper>}</>;
}

export default Modal;

const ModalWrapper = styled.div`
  background:black;
  opacity:0.97;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  top: 0;
  width: 100%;
  z-index: 200;
  position: fixed;
  transition: 0.5 ease-in;
  left: 0;
`;