import React from 'react';
import ReactDOM from 'react-dom';
//import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { HashRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { FileContextProvider } from './components/create-soundbit/context/fileContext';


//const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render( 
//   <React.StrictMode>
//    <HashRouter>
//    <FileContextProvider>
// 			<App />
// 		</FileContextProvider>
//    </HashRouter>
//   </React.StrictMode>
//  );

ReactDOM.render(
	<React.StrictMode>
    <Router>
		<FileContextProvider>
			<App />
		</FileContextProvider>
    </Router>
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();




