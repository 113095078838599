import React from 'react';
import { Routes, Route} from "react-router-dom";
import Fileupload from './components/pages/fileupload/fileupload'
import AllData from './components/pages/dashboard/AllData';
import EditData from './components/pages/dashboard/EditData';
import ViewInfo from './components/pages/dashboard/ViewInfo';
import LandingPage from './components/pages/landingpage/LandingPage';
import GetBpm from './components/pages/getBpm/GetBpm';
import SoundbitLayout from './components/create-soundbit/SoundbitLayout';
import TextToSpeech from './components/pages/TextToSpeech/TextToSpeech';
import ConvertAudio from './components/pages/ConvertAudio/ConvertAudio';
import EncodeFile from './components/pages/encodeFile/EncodeFile';
import AudioUpload from './components/create-soundbit/AudioUpload';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage/>} /> 
      <Route path="/bpm" element={<GetBpm/>} /> 
      <Route path="/create-soundbit" element={<AudioUpload/>} /> 
      <Route path="/encode-file" element={<EncodeFile/>} />
      <Route path="/loopaudio" element={<SoundbitLayout/>} />
      <Route path="/text-to-speech" element={<TextToSpeech/>} />
      <Route path="/convert-audio" element={<ConvertAudio/>} />
      <Route path="/file-bulk-upload" element={<Fileupload/>} /> 
      <Route path="/dashboard" element={<AllData/>} />
      <Route path="/updatefile/:id" element={<EditData/>} />
      <Route path="/view-info/:id" element={<ViewInfo/>} />
      <Route path="*" element={<p>404</p>} />
    </Routes> 
  );
}

export default App;
