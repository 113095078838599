import React from 'react'




const Footer = ()=> {
  return (
    <div className='h-[60px] w-full bottom-0 bg-gray-100 mb-0 mt-5 fixed'>
    <p className='ml-5 p-5 items-center font-bold'>@2023 stemgenerator : All right reserved </p>
    </div>
  )
}

export default Footer