import React, { useState, useEffect } from 'react'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall';
import Modal from '../../modal/Modal'
import styled from "styled-components";
import CloseModal from '../../CloseModal';
import Navbar from '../../navbar/Navbar'
import {CgArrowLongRight,CgArrowLongLeft} from 'react-icons/cg'
import {BiEdit} from 'react-icons/bi'
import {GiDrumKit} from 'react-icons/gi'
import {CiNoWaitingSign} from 'react-icons/ci'
import {CgPiano} from 'react-icons/cg'
import {FaMicrophone,FaGuitar,FaDrum} from 'react-icons/fa'
import {RiDeleteBin6Line} from 'react-icons/ri'
import {Link, useNavigate} from 'react-router-dom'
import {api,base} from '../../utils/api'
import ExportCsv from './ExportCsv';


import Alert from '../../alert/Alert';
import DragAndDrop from '../../draganddrop/DragAndDrop';
import SinglefileUpload from '../../draganddrop/SinglefileUpload';
import SearchInput from '../../searchInput/SearchInput';
import AudioDisplay from './AudioDisplay';
import UpdateDatabaseModal from './UpdateDatabaseModal';


//import Audio from '../audio'RiDeleteBin6Line


const AllData = ()=> {
  const [loading, setLoading] = useState(true);
  const [closeAlert,setCloseAlert] = useState(false)
  const [modalOpen,setModalOpen] = useState(false)
  const [dmessage,setDmessage] = useState("")
  const [spliteMessage, setSplittedMessage] = useState('')
  const [splitloader, setSplitLoader] = useState(true);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [audios, setAudios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [page_size, setPage_size] = useState(20);
  const [totalRow, setTotalRow] = useState(0);
  const [begin, setBegin] = useState("");
  const [end, setEnd] = useState("");
  const [id, setId] = useState("");
  const [audioId, setAudioId] = useState();
  const [dropdown,setDropdown] = useState(false);
  const [drumStem,setDrumStem] = useState([])
  const [pianoStem,setPianoStem] = useState([])
  const [bassStem,setBassStem] = useState([])
  const [instrumentalStem,setInstrumentalStem] = useState([])
  const [vocalStem,setVocalStem] = useState([])
  const [uploadOpen,setUploadOpen] = useState(false)
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [queryMessage, setQueryMessage] = useState("");
  const [col, setCol] = useState('');
  const [colMessage, setColMessage] = useState(null);
  const [allColumn, setAllColumn] = useState(false);
  const [onClose, setOnClose] = useState(false);

  const [minId, setMinId] = useState('');
  const [maxId, setMaxId] = useState('');

  setTimeout(() => {
    setQueryMessage('');
  }, 7000);

  setTimeout(() => {
    setColMessage('');
  }, 6000);

  const handleSearchByRange = async () => {
    try {
      const response = await axios.get(api + `/get_queryset/?min_id=${minId}&max_id=${maxId}`);
      let arr = response.data.results;
      setResults(response.data.results);
      setBegin(295364-Number(response.data.results[0].id ))
      setEnd(295364-Number(response.data.results[arr.length - 1].id))
    } catch (error) {
      console.error('Error searching by ID range:', error);
    }
  };
  
  const navigate = useNavigate();
  
    const getAudios = (page,page_size)=>{
        axios.get(api + `/audios/?page=${page}&page_size=${page_size}`).then(
            response =>{
             console.log("new response",response)
             let arr = response.data.results;
             setResults(arr)
             setTotalRow(response.data.total_items)
             setBegin(295364-Number(response.data.results[0].id))
             setEnd(295364-Number(response.data.results[arr.length - 1].id))
             setLoading(false)
             console.log("response.data ok")
            }
        ).catch(
             err =>{
             console.log(err)
             }
        )
    }

    const handleUpload = (id)=>{
        setUploadOpen(prev=>!prev)
        setAudioId(id)
    }

    const handleStem = (id)=>{
      setSplittedMessage("")
      getSpleetedAudios(id)
    }
    

    const openDropdown  = (id)=>{
       setId(id);
       setDropdown(prev=>!prev)
    }

    const closeDropdown  = (id)=>{
      setId("");
      setDropdown(prev=>!prev);
      setBassStem([]);
      setPianoStem([]);
      setInstrumentalStem([]);
      setDrumStem([]);
      setVocalStem([]);
   }


    const getSpleetedAudios = (id)=>{ 
      axios.get(base + `/splited-audios/${id}`).then(
          response =>{
           console.log(response.data)
           setVocalStem(response.data.data.filter(audio => audio.stemType == "v"))
           setPianoStem(response.data.data.filter(audio => audio.stemType == "p"))
           setBassStem(response.data.data.filter(audio => audio.stemType == "b"))
           setInstrumentalStem(response.data.data.filter(audio => audio.stemType == "i"))
           setDrumStem(response.data.data.filter(audio => audio.stemType == "d"))
          }
      ).catch(
           err =>{
           console.log(err)
           }
      )
    }
  

   
    
    useEffect(() => {
      getAudios(currentPage,page_size);
    }, [currentPage]);
  
    const handlePageChange = (newPage) => {
      if (newPage >= 1) {
        setCurrentPage(newPage);
      }
    };
    



    const deleteAudio = (id)=>{ 
      setDeleteLoader(id) 
      axios.get(base + `/delete-audio/${id}`).then(response =>{
           setDeleteLoader(null) 
           setCloseAlert(prev=>!prev)
           setDmessage(response.data.data)
           console.log(response.data.data)
           getAudios(currentPage,page_size)
          }
      ).catch(
           err =>{
           console.log(err)
           setDmessage("...oops something went wrong" )
           setDeleteLoader(null) 
           setCloseAlert(prev=>!prev)
           }
      )
    }

    const drums_spleet = (id)=>{
      setSplitLoader(prev=>!prev) 
      axios.get(base + `/split_drums/${id}`).then(
          response =>{
           console.log(response.data)
           getSpleetedAudios(id)
           setSplitLoader(prev=>!prev) 
          }
      ).catch(
           err =>{
           console.log(err)
           setSplittedMessage(err.message)
           setSplitLoader(prev=>!prev) 
           }
      )
    }

    const bass_spleet = (id)=>{
      setSplitLoader(prev=>!prev) 
      axios.get(base + `/split_bass/${id}`).then(
          response =>{
           console.log(response.data)
           getSpleetedAudios(id)
           setSplitLoader(prev=>!prev) 
          }
      ).catch(
           err =>{
           console.log(err)
           setSplittedMessage(err.message)
           setSplitLoader(prev=>!prev) 
           }
      )
    }

    const piano_spleet = (id)=>{
      setSplitLoader(prev=>!prev) 
      axios.get(base + `/split_piano/${id}`).then(
          response =>{
           console.log(response.data)
           getSpleetedAudios(id)
           setSplitLoader(prev=>!prev) 
          }
      ).catch(
           err =>{
           console.log(err)
           setSplittedMessage(err.message)
           setSplitLoader(prev=>!prev) 
           }
      )
    }

    const vocals_spleet = (id)=>{
      setSplitLoader(prev=>!prev) 
      axios.get(base + `/split_vocals/${id}`).then(
          response =>{
           console.log(response.data)
           getSpleetedAudios(id)
           setSplitLoader(prev=>!prev) 
          }
      ).catch(
           err =>{
           console.log(err)
           setSplittedMessage(err.message)
           setSplitLoader(prev=>!prev) 
           }
      )
    }

    const instrumentals_spleet = (id)=>{
      setSplitLoader(prev=>!prev) 
      axios.get(base + `/split_instrumentals/${id}`).then(
          response =>{
           console.log(response.data)
           getSpleetedAudios(id)
           setSplitLoader(prev=>!prev) 
          }
      ).catch(
           err =>{
           console.log(err)
           setSplittedMessage(err.message)
           setSplitLoader(prev=>!prev) 
           }
      )
    }

    
    const handleOnchange = (e)=>{
       setQuery(e.target.value)
    }
    
    const handleSearch = async () => {
        if (query === ""){
          setQueryMessage("No keyword entered")
          return
        }
        if (query.length < 4){
          setQueryMessage(" keyword must be length 4 or more.. ")
          return
        }

      try {
        const response = await axios.get(base + `/search-data/${query}`);
        let arr = response.data.results;
      setResults(response.data.results);
      setBegin(295364-Number(response.data.results[0].id))
      setEnd(295364-Number(response.data.results[arr.length - 1].id))
        console.log(response.data.results)
        if (response.data.results.length < 1){
          setQueryMessage(`No result found for ${query}`)
        }
      } catch (error) { 
        console.error('Error fetching search results:', error);
        setQueryMessage(`Error fetching search results: ${error}`)
      }
    };


    const handleArtistSearch = (e)=>{
      setCol("artist")
      colunmSearch("artist",e.target.value)
   }

   const handleProcessed = (e)=>{
    setCol("is_processed")
    colunmSearch("is_processed",e.target.value)
     }

   const handleUpcSearch = (e)=>{
    setCol("upc")
    colunmSearch("upc",e.target.value)
 }
 const handleBPMSearch = (e)=>{
  setCol("bpm")
  colunmSearch("bpm",e.target.value)
}
const handleConvertedSearch = (e)=>{
  setCol("converted")
  colunmSearch("converted",e.target.value)
}
const handleGenreSearch = (e)=>{
  setCol("genre")
  colunmSearch("genre",e.target.value)
}
const handleISRCSearch = (e)=>{
  setCol("isrc")
  colunmSearch("isrc",e.target.value)
}

 const handleTitleSearch = (e)=>{
  setCol("title")
  colunmSearch("title",e.target.value)
}
const handleCatNumSearch = (e)=>{
  setCol("catnum")
  colunmSearch("catnum",e.target.value)
}

const handleReleaseNameSearch = (e)=>{
  setCol("releasename")
  colunmSearch("releasename",e.target.value)
}
const handleDeletedSearch = (e)=>{
  setCol("deleted")
  colunmSearch("deleted",e.target.value)
}

    const colunmSearch = async (col,colQuery) => {
    
    try {
      const response = await axios.get(base + `/search-column/${col}/${colQuery}/${page_size}`);
      let arr = response.data.results;
      console.log("this is arr",response.data.results)
      setResults(response.data.results);
      setBegin(295364-Number(response.data.results[0].id))
      setEnd(295364-Number(response.data.results[arr.length - 1].id))
      if (response.data.results.length < 1){
        setColMessage(`No result found for ${colQuery} `)
      }
      
    } catch (error) { 
      console.log(`Not Found : Error fetching search results for ${colQuery}`)
      
    }
  };

  const handleSelectChange = (event) => {
    setPage_size(event.target.value);
    getAudios(currentPage,event.target.value);
  };


    return (
      <>
       <Navbar/>
       {loading ? (
        <div className=" mt-[200px] items-center justify-center">
          <LoaderSmall/>
        </div>
       
       ):(

       <div className="w-full px-4">
           <section className="flex flex-col mx-4 mt-2  h-auto">
            <div className='flex  items-center'>
            <div className='flex '>
            <div className='flex flex-col'>
            <SearchInput handleSearch={handleSearch} handleOnchange={handleOnchange} query={query}/>
            <p className='text-red-500 text-xs'>{queryMessage}</p>
            <p className="flex w-full mb-[-15px] mt-6 font-semibold text-sm text-gray-600">Showing <span className='text-blue-800 mx-1 font-bold'>{begin}</span> to <span className='text-blue-800 mx-1 font-bold'>{end}</span> of <span className='text-blue-800 mx-1 font-bold'>{totalRow}</span> entries...</p>
            </div>
            <ExportCsv data={results}/>
            <div className='flex mx-4 space-x-1 text-xs font-semibold'>
            <label htmlFor="mySelect">Show</label>
            <select id="mySelect" value={page_size} className='h-5 border border-gray-500 w-12' onChange={handleSelectChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p>entries</p>
            </div>
            <button onClick={()=>{setOnClose(true)}} className='bg-black rounded-md text-white text-xs px-2 py-1 h-[30px]'>Update_Database</button>
           
            </div>
                       
            {/* <div className="justify-center mt-0 border rounded-sm py-1 px-1" >
      <h1 className="text-xs font-semibold mb-1">Search by ID Range</h1>
      <div className="flex space-x-2 mb-2">
        <input
          type="number"
          className="p-1 border rounded-sm w-[80%] h-6 text-xs"
          placeholder="Min ID"
          value={minId}
          onChange={(e) => setMinId(e.target.value)}
        />
        <input
          type="number"
          className="p-1 border rounded-sm w-[80%] h-6 text-xs"
          placeholder="Max ID"
          value={maxId}
          onChange={(e) => setMaxId(e.target.value)}
        />
        <button
          className="bg-blue-700 text-xs text-white px-2 py-1 rounded-lg hover:bg-blue-600"
          onClick={handleSearchByRange}
        >
          Search
        </button>
      </div> */}
      {/* Display search results */}
      {/* <ul>
        {results.map((item) => (
          <li key={item.id}>{item.name}</li>
        ))}
      </ul> */}
    {/* </div> */}
          <div className='flex flex-col w-full justify-end '>
            <div className='flex w-full justify-end  font-semibold text-sm text-gray-600'>
        <button className='flex' onClick={() => handlePageChange(currentPage - 1)}><CgArrowLongLeft className='mt-1 mx-2'/>Previous</button>
        <span className='mx-3 text-red-700'>Page {currentPage}</span>
        <button className='flex' onClick={() => handlePageChange(currentPage + 1)}>Next <CgArrowLongRight className='mt-1 mx-2'/></button>
        </div>

      </div>
      </div>
     <div className=''>
            <div className='flex flex-col justify-center items-center mt-3 mb-[-15px] '>
            <p className='font-bold text-blue-900'>Full Audio database</p> 
            {colMessage && <p className='text-red-500 text-sm font-light'>{colMessage}</p>}
            </div>
            {allColumn && <div className='flex justify-end text-end cursor-pointer ' onClick={()=>setAllColumn(false)}><CgArrowLongLeft className='font-bold text-[30px]'/> <p className='text-sm font-bold'>..Less</p></div>}
      {!allColumn  && <div className='flex justify-end text-end cursor-pointer' onClick={()=>setAllColumn(true)}><p className='text-sm font-bold'>More..</p><CgArrowLongRight className='font-bold text-[30px]'/></div>}
      </div>
      <div className={`w-full h-[380px] ${allColumn ? 'overflow-x-scroll' : 'overflow-x-hidden'} border border-stone-700 rounded-md`}>
            <table className="table-auto  border-collapse border border-slate-400 "  >
            <thead className='text-xs'>
                <tr>
                <th className='border-collapse border border-slate-400 py-2'>#</th>
                <th className='border-collapse border border-slate-400 py-2'>Tracks</th>
                <th className='border-collapse border border-slate-400 py-2'>View Stems</th>
                <th className='border-collapse border border-slate-400 py-1 flex flex-col items-center'>
                <p>Artist</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs font-light"
                placeholder="search Artist"
                onChange={handleArtistSearch}
                onPaste={handleArtistSearch}
                />
                  </th>
                <th className='border-collapse border border-slate-400 py-1  flex-col items-center'>
                <p>Track_Title</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs  font-light"
                placeholder="search Track Title"
                onChange={handleTitleSearch}
                onPaste={handleTitleSearch}
                />
                  </th>
                <th className='border-collapse border border-slate-400 py-1  flex-col items-center'>
                <p>CatalogNo</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs  font-light"
                placeholder="search CatalogNum"
                onChange={handleCatNumSearch}
                onPaste={handleCatNumSearch}
                />
                  </th>
                <th className='border-collapse border border-slate-400 py-1  flex-col items-center'>
                <p>Release_Name</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs  font-light"
                placeholder="search ReleaseName"
                onChange={handleReleaseNameSearch}
                onPaste={handleReleaseNameSearch}
                />
                </th>
                <th className='border-collapse border border-slate-400 py-1 flex-col items-center'>
                <p>Deleted</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs font-light"
                placeholder="search Deleted"
                onChange={handleDeletedSearch}
                />
                  </th>
                <th className='border-collapse border border-slate-400 py-1 flex-col items-center'>
                <p>UPC</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs  font-light"
                placeholder="search UPC"
                onChange={handleUpcSearch}
                onPaste={handleUpcSearch}
                />
                  </th>
                <th className='border-collapse border  border-slate-400'>
                <p>BPM</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs  font-light"
                placeholder="search BPN"
                onChange={handleBPMSearch}
                onPaste={handleBPMSearch}
                />
                  
                  </th>
                <th className='border-collapse border  border-slate-400'>
                <p>ISRC</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs  font-light"
                placeholder="search ISRC"
                onChange={handleISRCSearch}
                onPaste={handleISRCSearch}
                />
                  </th>
                <th className='border-collapse border  border-slate-400'>
                <p>Genre</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs  font-light"
                placeholder="search Genre"
                onChange={handleGenreSearch}
                onPaste={handleGenreSearch}
                />
                  </th>
                  
                  <th className='border-collapse border  border-slate-400'>
                <p>is_processed</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs  font-light"
                placeholder="search is_processed"
                onChange={handleProcessed}
                onPaste={handleProcessed}
                />
                  </th>
                  <th className='border-collapse border  border-slate-400'>Keys_</th>
                <th className='border-collapse border  border-slate-400'>Mode</th>
                  <th className='border-collapse border  border-slate-400'>LabelName</th>
                <th className='border-collapse border  border-slate-400'>Release_date</th>
                <th className='border-collapse border  border-slate-400'>TrackNo</th>
                <th className='border-collapse border  border-slate-400'>Download</th>
                <th className='border-collapse border  border-slate-400'>Data_Type</th>
                <th className='border-collapse border  border-slate-400'>C_Line</th>
                <th className='border-collapse border  border-slate-400'>
                <p>Converted</p>
                <input
                type="text"
                className="p-2 py-3 mx-1 border rounded-sm w-[70px] h-[10px] text-xs  font-light"
                placeholder="search BPN"
                onChange={handleConvertedSearch}
                onPaste={handleConvertedSearch}
                />
                  
                  </th>
                <th className='border-collapse border  border-slate-400'>Total_Tracks</th>
                <th className=' py-2 col-span-2 '></th>
                </tr>
             </thead>
           {results.length > 0 ?
          <AudioDisplay
          audios={results}
          vocalStem={vocalStem}
          drumStem={drumStem}
          bassStem={bassStem}
          pianoStem={pianoStem}
          closeDropdown={closeDropdown}
          instrumentals_spleet={instrumentals_spleet}
          bass_spleet={bass_spleet}
          piano_spleet={piano_spleet}
          drums_spleet={drums_spleet}
          instrumentalStem={instrumentalStem}
          spliteMessage={spliteMessage}
          base={base}
          handleStem={handleStem}
          handleUpload={handleUpload}
          openDropdown={openDropdown}
          vocals_spleet={vocals_spleet}
          dropdown={dropdown}
          deleteLoader={deleteLoader}
          deleteAudio={deleteAudio}
          splitloader={splitloader}
          id={id}
           />
            :
            <p className='flex justify-center items-center font-semibold text-md'>No result found</p>
           }
           
           </table>
           </div>
           </section>
       
      </div>
      )}
      
     {closeAlert && <Alert  closeAlert={()=>setCloseAlert(prev=>!prev)}>
        <p className={`${dmessage === 'delete successful' ? 'text-green-500' : 'text-red-500'}`}>{dmessage}</p>
      </Alert>}


      
            {uploadOpen && <div role="dialog"  className="flex bg-gray-600 bg-opacity-75 justify-center items-center overflow-x-hidden 
            overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-450 ease-in-out"  id="modal">
                <div role="alert" class="container mx-auto w-11/12 md:w-2/3 ">
                    <div class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <SinglefileUpload audioId={audioId} getAudios={()=>getAudios(1,page_size)}/>
                        <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 
                        ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" onClick={handleUpload} >
                            <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x"
                             width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" 
                             stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>}
            <UpdateDatabaseModal onClose={onClose} setOnClose={setOnClose}/>
    </>
    )
  }


export default AllData

const ModalContainer = styled.div`
  background: white;
  opacity:1;
  min-height: auto;
  padding:10px;
  width: 600px;
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`;
